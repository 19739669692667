<template>
  <div>
    <div class="row">
      <div class="report-header">
        <div class="left">
          <div class="logo">
            <a href="#"><img src="" alt="" /></a>
          </div>
          <div class="header">
            <h1>Assignment Report</h1>
            <p>Date: {{ formatDate(date) }} {{ formatDate(currentTimeInfo) }}</p>
          </div>
        </div>
        <div class="right">
          <button @click="close">Close</button>
        </div>
      </div>
      <p class="print" @click="printScreen()"><span class="material-icons">print</span> Print</p>
      <div class="report-content">
        <!-- <h2>Assignment-20-4-2022-17-19</h2> -->
        <h2>{{ description }}</h2>
        <div class="report-content-inside">
          <div class="col m8 s12 no-padding">
            <div class="report-detail">
              <div class="col m6 s12 no-padding">
                <p>
                  Questions Correct :
                  <span>{{ QuestionsCorrect }}/{{ TotalAttemptQuestions }}</span>
                </p>
                <p>
                  Average Time : <span>{{ AverageTime }}</span>
                </p>
              </div>
              <div class="col m6 s12 no-padding">
                <p>
                  Attempted:
                  <span>{{ AttemptedRatio }}% ({{ TotalAttemptQuestions }}/{{ AverageTime }})</span>
                </p>
                <p>
                  Total Time : <span>{{ TotalTimeTaken }} min</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col m4 s12 report-content-right">
            <p>Accuracy (in %)</p>
            <div id="outer-circle">
              <div id="inner-circle">
                <div class="charts">{{ Accuracy }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col s12 report-footer">
        <p>Session Details</p>
      </div>
      <table class="session-detail">
        <thead>
          <tr>
            <td style="padding-left: 20px">Question No</td>
            <td>Question Code</td>
            <td>Is correct?</td>
            <td>Correct answer</td>
            <td>Time taken</td>
            <td>Global correct time</td>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="(viewReport, index) in viewReportData" :key="index">
            <td style="padding-left: 20px">
              {{ index + 1 }}
            </td>
            <td>
              <a href="#" @click="viewQuestion(viewReport.QuestionId)">{{
                viewReport.QuestionCode
              }}</a>
            </td>
            <td>
              {{
                viewReport.IsCorrectlyAnswered === 1
                  ? "Yes"
                  : viewReport.IsCorrectlyAnswered === null
                  ? "Not Attend"
                  : "No"
              }}
            </td>
            <td>
              {{ viewReport.AnswerOption }}
            </td>
            <td>
              {{ formateSecond(viewReport.TIMETAKEN) }}
            </td>
            <td>{{ formateSecond(viewReport._AverageTimeTakenInSecCorrectQuestions) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Model -->
    <div v-for="(viewReport, index) in viewReportData" :key="index">
      <Modal
        :show="showConceptModal"
        @close="showConceptModal = false"
        width="80%"
        v-if="viewQuestionId === viewReport.QuestionId"
      >
        <template #body>
          <!-- <div slot="header"> -->
          <div class="concept-item-heading">
            <ul>
              <li
                class="concept-btn"
                :class="{ active: selectedTab === 1 }"
                @click="selectedTab = 1"
              >
                Question
              </li>
              <li
                class="concept-btn"
                :class="[{ active: selectedTab === 2 }]"
                @click="selectedTab = 2"
              >
                Solution
              </li>
              <div></div>
            </ul>
          </div>
          <!-- </div> -->
          <div class="row">
            <div class="concept-content">
              <div class="diagramDiv" v-if="selectedTab === 1">
                <div class="diagram-img">
                  <div class="concept-image">
                    <img :src="viewReport.QuestionDiagramURL" style="width: 100%" />
                  </div>
                </div>
              </div>
              <div class="diagramDiv" v-if="selectedTab === 2">
                <div class="diagram-img">
                  <div class="concept-image">
                    <img :src="viewReport.FullSolutionURL" style="width: 100%" />
                  </div>
                </div>
              </div>
            </div>
            <button
              class="waves-effect btn-flat"
              style="
                display: flex;
                justify-content: center;
                margin: auto;
                position: relative;
                top: 15px;
              "
              @click="showConceptModal = false"
            >
              Close
            </button>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import Vue from "vue";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("MMM DD, YYYY");
//   }
//   return value;
// });

// Vue.filter("formateSecond", (value) => {
//   if (value) {
//     return (value / 60).toFixed(2);
//     // return moment.utc(value * 1000).format("HH:mm:ss");
//   }
//   return value;
// });
export default {
  data() {
    return {
      assignmentData: [],
      date: "",
      description: "",
      showConceptModal: false,
      selectedTab: 1,
      currentTimeInfo: "",
      viewReportData: [],
      viewQuestionId: null,
      StartedOn: "",
      CompletedOn: "",
      TotalAttemptQuestions: 1,
      QuestionsCorrect: 0,
      AttemptedRatio: 0,
      unAttemptedQues: 1,
      TotalTimeTaken: 0,
      totalTimeForAvg: 0,
      Accuracy: 0,
      AverageTime: 0,
      height: 0,
    };
  },
  components: {
    Modal,
  },
  activated() {
    this.TotalAttemptQuestions = 0;
    this.TotalTimeTaken = 0;
    this.totalTimeForAvg = 0;
    this.Accuracy = 0;
    this.AverageTime = 0;
    this.AttemptedRatio = 0;
    this.assignmentData = this.$route.params.assignmentData
      ? this.$route.params.assignmentData
      : "";
    // console.log(this.assignmentData);
    // this.TotalAttemptQuestions = this.assignmentData.TotalAttemptQuestions ? this.assignmentData.TotalAttemptQuestions : null;
    // this.date = this.assignmentData.CreatedOn ? this.assignmentData.CreatedOn : "";
    this.date = this.$route.params.CreatedOn ? this.$route.params.CreatedOn : "";
    // this.description = this.assignmentData.Description ? this.assignmentData.Description : "";
    this.description = this.$route.params.Description ? this.$route.params.Description : "";
    // this.currentTimeInfo = this.$route.params.currentTimeInfo ? this.$route.params.currentTimeInfo : "";
    this.ExamSessionId = this.$route.params.ExamSessionId ? this.$route.params.ExamSessionId : "";
    // console.log("currentTimeInfo:", this.currentTimeInfo);
    // console.log("ExamSessionId:", this.ExamSessionId);

    this.QuestionsCorrect = 0;
    this.$store.dispatch("showLoader", true);
    MobileAPI.getExamSessionReport(
      {
        ExamSessionId: this.ExamSessionId,
      },
      (resp) => {
        if (resp.responseCode === 200) {
          this.viewReportData = resp.data;
          // console.log("viewReportData:", this.viewReportData);
          // Question Correct Ratio
          this.viewReportData.forEach((item) => {
            this.totalTimeForAvg += item.TIMETAKEN;
            this.TotalTimeTaken += item.TIMETAKEN;
            if (item.IsCorrectlyAnswered !== null) {
              this.TotalAttemptQuestions += 1;
              this.QuestionsCorrect += Number(item.IsCorrectlyAnswered);
            } else {
              this.unAttemptedQues += 1;
            }
          });
          this.TotalTimeTaken = (this.TotalTimeTaken / 60).toFixed(2);
          // Attempted Ratio
          this.AttemptedRatio = Math.trunc(
            (this.TotalAttemptQuestions / this.viewReportData.length) * 100,
          );
          // Accuracy Ratio
          this.Accuracy = Math.trunc((this.QuestionsCorrect / this.TotalAttemptQuestions) * 100);
          this.AverageTime = (this.totalTimeForAvg / this.TotalAttemptQuestions / 60).toFixed(2);
        }
      },
    );
    this.$store.dispatch("showLoader", false);
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("MMM DD, YYYY");
      }
      return value;
    },
    formateSecond(value) {
      if (value) {
        return (value / 60).toFixed(2);
        // return moment.utc(value * 1000).format("HH:mm:ss");
      }
      return value;
    },
    close() {
      this.$router.go(-1);
    },
    closeQuestionsModal() {
      this.showConceptModal = false;
    },
    viewQuestion(QuestionId) {
      this.selectedTab = 1;
      this.showConceptModal = true;
      this.viewQuestionId = QuestionId;
    },
    printScreen() {
      window.print();
    },
  },
};
</script>

<style scoped>
.report-header {
  line-height: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
}

.report-header .left {
  display: flex;
  justify-content: space-between;
}

.report-header .header h1 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
}

.report-header .header p {
  font-size: 14px;
  margin: 0;
}

.report-header .right button {
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1.25px;
  background-color: #fff;
  border: 1px solid #666666;
  color: #666666;
  padding: 12px 15px;
  cursor: pointer;
  transition: 0.3s all ease-in;
}

.report-header .right button:hover {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #fff;
}

.print {
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  width: fit-content;
}

.print span {
  margin-right: 8px;
}

.report-content {
  background: white;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 6%);
  padding: 24px;
  margin-bottom: 44px;
}

.report-content-inside,
.report-footer {
  display: flex;
  border: none;
  transition: 0.5s all ease-in-out;
  background: #fff;
  border-radius: 4px;
}

.report-content h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  color: #212121;
  margin: 0;
}

.report-detail p {
  font-size: 16px;
}

.report-detail p span {
  color: #15a671;
  font-weight: 600;
}

.report-content-right {
  border-left: 1px solid #cecece;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-footer {
  padding: 0;
}

.report-footer p {
  padding: 18px 20px;
  font-size: 17px;
  font-weight: 600;
  color: #212121;
  margin: 0;
}

.session-detail {
  margin-bottom: 44px;
}

td {
  border-radius: 0;
}

.session-detail thead {
  background: #003b93;
  color: white;
}

.session-detail tbody tr {
  background-color: #fff;
}

.session-detail tbody tr:hover {
  color: #212121;
  background-color: #f1f7ff;
}

.session-detail tbody tr td:nth-child(1),
.session-detail tbody tr td:nth-child(2) a {
  color: #003b93;
  font-weight: 700;
}

.session-detail tbody tr td:nth-child(2) a:hover {
  text-decoration: underline;
}

/* Model CSS */

.concept-item-heading ul {
  display: flex;
  margin: 0;
}

.modal-fixed-footer .modal-content::-webkit-scrollbar {
  display: none;
}

.modal-fixed-footer .modal-content::-moz-scrollbar {
  display: none;
}

.modal-fixed-footer .modal-content::-o-scrollbar {
  display: none;
}

.modal-fixed-footer .modal-content::-google-ms-scrollbar {
  display: none;
}

.modal-fixed-footer .modal-content::-khtml-scrollbar {
  display: none;
}

.modal-fixed-footer,
.modal-header {
  padding: 0 !important;
}

.modal-fixed-footer .modal-content {
  overflow-y: unset !important;
  width: 100%;
}

.modal-body {
  overflow-y: unset !important;
}

.concept-item-heading ul .concept-btn {
  border-bottom: 1px solid #dee2e6;
  border-radius: 5px 5px 0 0;
  width: fit-content;
  font-size: 16px;
  margin-bottom: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #495057;
  font-weight: normal;
  transition: all 0.3s ease;
}

.concept-item-heading ul div {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  border-left: none;
  width: 100%;
}

.concept-item-heading ul .concept-btn:last-child {
  padding-left: 20px;
}

.concept-item-heading ul .concept-btn.active {
  border: 1px solid #dee2e6;
  border-bottom-color: transparent;
}

.concept-content .diagramDiv {
  position: relative;
  margin-top: 30px;
}

.concept-content .diagramDiv .diagramBtns {
  position: absolute;
  width: 100%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.concept-content .diagramDiv .diagramBtns .diagram-btn {
  position: absolute;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  background: #f9a825;
  border: 0.0625rem solid #f9a825;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.concept-content .diagramDiv .diagramBtns .diagramPre {
  left: 10px;
}

.concept-content .diagramDiv .diagramBtns .diagramNext {
  right: 10px;
}

.videoDIv {
  width: 100%;
  padding: 0 30px;
}

/*Circle chart */
#outer-circle {
  background: #e7e7e7;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  /*
    Child elements with absolute positioning will be
    positioned relative to this div
   */
}

#outer-circle .charts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #212121;
}

#inner-circle {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress {
  border: 1px solid gray;
  transform: rotate(180deg);
  height: 70px;
  width: 5px;
  border-radius: 5px;
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: red;
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: green;
}

@media screen and (max-width: 600px) {
  .report-content-inside {
    flex-direction: column-reverse;
  }

  .report-content-right {
    border-color: transparent;
    margin-bottom: 25px;
  }

  .report-detail p {
    margin: 0;
  }

  .report-content h2 {
    font-size: 18px;
  }
}
</style>
